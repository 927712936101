import clsx from "clsx";
import Button from "@/components/Button";
import posthog from "posthog-js";
import { pushEvent } from "@/lib/gtm";

const SubscriptionCards = ({
  prices,
  hasHadATrial,
  couponCode,
  authStore,
  variant,
  displayCurrency,
  currentStepIndex,
  logOnboardingStep,
  otherObjective,
  otherText,
  otherCategory,
}) => {
  if (!prices?.length) {
    return <p>No pricing plans available.</p>;
  }

  return prices.map((price) => {
    const isYearly = price.recurring?.interval === "year";

    const cost = price.unit_amount / 100;
    const planType = isYearly ? "annual" : "monthly";

    const handleSubmit = (e) => {
      e.preventDefault();

      posthog.capture("onboarding_plan_selected", {
        userId: authStore.currentUser?.id,
        plan: planType,
        cost,
        variant,
      });

      localStorage.setItem("checkout_frequency", planType);
      localStorage.setItem("checkout_trial", !hasHadATrial ? "true" : "false");
      localStorage.setItem("checkout_advertised_cost", cost.toString());

      pushEvent("checkout_start", {
        frequency: planType,
        advertised_cost: cost,
        trial: !hasHadATrial,
      });

      logOnboardingStep(
        currentStepIndex,
        "onboardingChooseYourPlan",
        authStore.currentUser,
        otherObjective,
        otherText,
        otherCategory
      );

      e.currentTarget.submit();
    };

    return (
      <form
        key={price.id}
        action="/api/plus/checkout"
        method="POST"
        data-rewardful="true"
        onSubmit={handleSubmit}
      >
        {couponCode && <input type="hidden" name="coupon" value={couponCode} />}
        <input type="hidden" name="priceId" value={price.id} />
        <input
          type="hidden"
          name="nGNndCdFpnDazku"
          value={!hasHadATrial ? "scUmYobrToNvMkY" : "WrmUyxZMkBbxBrU"}
        />
        <input
          type="hidden"
          name="email"
          value={authStore.currentUser?.email}
        />

        <button
          className={clsx(
            "group relative w-full overflow-hidden rounded-2xl pb-8 leading-none transition",
            isYearly ? "bg-acid-500 text-zinc-800" : "bg-white text-zinc-800"
          )}
          type="submit"
        >
          <span className="inline-block w-full rounded-t-2xl bg-zinc-800 px-6 py-2 text-base text-white">
            {price.metadata.title}
          </span>

          <span className="mt-8 block text-2xl sm:text-3xl">
            {price.metadata.crossed_price && (
              <s className="mr-2 text-[#787878]">
                {price.metadata.crossed_price}
              </s>
            )}
            {price.metadata.heading}
          </span>

          <span className="mt-3 block text-sm opacity-50 sm:text-base">
            {price.metadata.subheading}
          </span>

          {!hasHadATrial && price.metadata.trialText && (
            <span className="mt-4 block text-sm sm:text-base">
              {price.metadata.trialText}
            </span>
          )}

          <div className="mt-4 flex flex-col items-center">
            <Button theme="light">
              {!hasHadATrial ? "Start 5-Day Free Trial" : "Join The Mob"}
            </Button>
          </div>
        </button>
      </form>
    );
  });
};

export default SubscriptionCards;
