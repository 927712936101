import { ConfirmDialog } from "@/components/ConfirmDialog";
import { useAuthContext } from "@/contexts/AuthContext";
import { useStores } from "@/hooks/useStores";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useRef, useState, useEffect } from "react";
import Loading from "@/components/Loading";
import { observer } from "mobx-react";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import SubscriptionCards from "./SubscriptionCards";

export const ChooseYourPlan = observer(() => {
  const variant = useFeatureFlagVariantKey("cro-web-98");

  const [couponCode, setCouponCode] = useState("");
  const router = useRouter();
  const [smallModalOpen, setSmallModalOpen] = useState(false);
  const { hasHadATrial } = useSubscriptionPermission();
  const { authStore } = useStores();
  const {
    displayCurrency,
    updateProgress,
    logOnboardingStep,
    currentStepIndex,
    otherObjective,
    otherText,
    otherCategory,
  } = useAuthContext();
  const routerRef = useRef(router);
  const annualSubFormRef = useRef(null);

  const [prices, setPrices] = useState([]);
  const [loadingPrices, setLoadingPrices] = useState(true);

  const productIdMap = {
    control: "prod_NEBs46JstvCCwt", // £4.99 & £35
    middle_price: "prod_Rus0Rb4MwgpWmO", // £5.99 & £40
    high_price: "prod_RoBgGartDTuqPX", // £9.99 & £49
  };

  const productId = productIdMap[variant] || productIdMap.control;

  useEffect(() => {
    async function fetchPrices() {
      try {
        const res = await fetch(
          `/api/plus/prices?productId=${productId}${
            variant ? `&experimentGroup=${variant}` : ""
          }`
        );
        const data = await res.json();

        console.log("Fetched prices from API:", data.prices);
        setPrices(data.prices);
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      } finally {
        setLoadingPrices(false);
      }
    }
    fetchPrices();
  }, [productId, variant]);

  useEffect(() => {
    if (sessionStorage.getItem("couponCode")) {
      setCouponCode(sessionStorage.getItem("couponCode"));
    }

    // this section should be immediately continuable
    updateProgress("onboardingChooseYourPlan", true, true);
  }, []);

  const handleSmallPopupClose = () => {
    setSmallModalOpen(false);
  };

  const handleClose = () => {
    setSmallModalOpen(false);
    routerRef.current.push("/", "/", { shallow: true });
  };

  /* 
    Keeping this in for now incase we want to restore the functionality
    of calling the "continue as free" modal
   */
  const handleFreeMemberClick = (e) => {
    e.preventDefault();
    setSmallModalOpen(true);
  };

  return couponCode ? (
    <>
      <Loading />
      <div className="hidden">
        <SubscriptionCards
          prices={prices}
          hasHadATrial={hasHadATrial}
          couponCode={couponCode}
          authStore={authStore}
          variant={variant}
          displayCurrency={displayCurrency}
          currentStepIndex={currentStepIndex}
          logOnboardingStep={logOnboardingStep}
          otherObjective={otherObjective}
          otherText={otherText}
          otherCategory={otherCategory}
        />
      </div>
    </>
  ) : (
    <>
      <div className="flex w-full flex-col gap-6">
        <h1 className={clsx("heading-sm--spatial")}>Choose your plan</h1>
        {loadingPrices ? (
          <Loading />
        ) : (
          <SubscriptionCards
            prices={prices}
            hasHadATrial={hasHadATrial}
            couponCode={couponCode}
            authStore={authStore}
            variant={variant}
            displayCurrency={displayCurrency}
            currentStepIndex={currentStepIndex}
            logOnboardingStep={logOnboardingStep}
            otherObjective={otherObjective}
            otherText={otherText}
            otherCategory={otherCategory}
          />
        )}
      </div>

      <ConfirmDialog
        open={smallModalOpen}
        onOpenChange={setSmallModalOpen}
        title="Are you sure?"
        description="Sign up for a Premium account to get unlimited access to our recipes, meal plans and so much more."
        confirmLabel="Choose Plan"
        cancelLabel="Close"
        onConfirm={handleSmallPopupClose}
        onCancel={handleClose}
      />
    </>
  );
});
